<script lang="ts" setup>
import {navigateTo} from '#imports'
import type Menu from 'primevue/menu'

const {account, logout} = useAuth()
const menu = ref<typeof Menu>()

const toggleMenu = (event: MouseEvent) => {
  menu.value?.toggle(event)
}
</script>

<template>
  <button v-if="account" type="button" class="layout-topbar-action" @click="toggleMenu">
    <i class="pi pi-user"></i>
    <span>Profile</span>
  </button>
  <Menu
    :model="[
      {
        command: () => navigateTo({
          name: 'account'
        }),
        icon: 'pi pi-user',
        label: 'Tu cuenta'
      },
      {
        command: () => logout(),
        icon: 'pi pi-sign-out',
        label: 'Cerrar sesión'
      }
    ]"
    popup
    ref="menu"
  />
</template>
