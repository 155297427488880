<script lang="ts" setup>
import AppMenuItem from './AppMenuItem.vue'

const model = ref<LayoutMenuItem[]>([
  {
    label: 'Inicio',
    items: [{ label: 'Panel', icon: 'pi pi-fw pi-home', to: '/' }]
  },
  {
    label: 'Ticketing',
    items: [
      { label: 'Eventos', icon: 'pi pi-calendar', to: '/ticketing/events' },
      { label: 'Pedidos', icon: 'pi pi-list', to: '/ticketing/orders' },
      { label: 'Usuarios', icon: 'pi pi-user', to: '/users' },
    ]
  },
])
</script>

<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in model" :key="item">
      <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
      <li v-if="item.separator" class="menu-separator"></li>
    </template>
  </ul>
</template>
