<script lang="ts" setup>
const route = useRoute()

const { layoutState, setActiveMenuItem, onMenuToggle } = useLayout()

const props = withDefaults(defineProps<{
  item: LayoutMenuItem,
  index?: number,
  root?: boolean,
  parentItemKey?: string
}>(), {
  index: 0,
  root: true
})

const isActiveMenu = ref<boolean>(false)
const itemKey = ref<string>()

onBeforeMount(() => {
  itemKey.value = props.parentItemKey ? props.parentItemKey + '-' + props.index : String(props.index)

  const activeItem = layoutState.activeMenuItem

  isActiveMenu.value = activeItem === itemKey.value || activeItem ? activeItem.startsWith(itemKey.value + '-') : false
})

watch(
  () => layoutState.activeMenuItem,
  (newVal) => {
    if (newVal) {
      isActiveMenu.value = newVal === itemKey.value || newVal.startsWith(itemKey.value + '-')
    } else {
      isActiveMenu.value = false
    }
  }
)

const itemClick = (event: MouseEvent, item: LayoutMenuItem) => {
  if (item.disabled) {
    event.preventDefault()
    return
  }

  if ((item.to || item.url) && (layoutState.staticMenuMobileActive || layoutState.overlayMenuActive)) {
    onMenuToggle()
  }

  if (item.command) item.command({ originalEvent: event, item: item })

  const foundItemKey = item.items ? (isActiveMenu.value ? props.parentItemKey : itemKey) : itemKey.value;

  setActiveMenuItem(foundItemKey)
}

const checkActiveRoute = (item: LayoutMenuItem) => {
  return route.path === item.to
}
</script>

<template>
  <li :class="{ 'layout-root-menuitem': root, 'active-menuitem': isActiveMenu }">
    <div v-if="root && item.visible !== false" class="layout-menuitem-root-text">{{ item.label }}</div>
    <a v-if="(!item.to || item.items) && item.visible !== false" :href="item.url" @click="itemClick($event, item)" :class="item.class" :target="item.target" tabindex="0">
      <i :class="item.icon" class="layout-menuitem-icon"></i>
      <span class="layout-menuitem-text">{{ item.label }}</span>
      <i class="pi pi-fw pi-angle-down layout-submenu-toggler" v-if="item.items"></i>
    </a>
    <nuxt-link v-if="item.to && !item.items && item.visible !== false" @click="itemClick($event, item)" :class="[item.class, { 'active-route': checkActiveRoute(item) }]" tabindex="0" :to="item.to">
      <i :class="item.icon" class="layout-menuitem-icon"></i>
      <span class="layout-menuitem-text">{{ item.label }}</span>
      <i class="pi pi-fw pi-angle-down layout-submenu-toggler" v-if="item.items"></i>
    </nuxt-link>
    <Transition v-if="item.items && item.visible !== false" name="layout-submenu">
      <ul v-show="root ? true : isActiveMenu" class="layout-submenu">
        <app-menu-item v-for="(child, i) in item.items" :key="`${index}-${i}`" :index="i" :item="child" :parentItemKey="itemKey" :root="false"></app-menu-item>
      </ul>
    </Transition>
  </li>
</template>
