<script lang="ts" setup>
import AppLayout from '~/components/layout/AppLayout.vue'
import {useFavicon} from '@vueuse/core'

const {company} = useCompany()
const siteName = computed<string>(() => company.value ? company.value.name : 'Cargando...')

if (company.value) {
  useFavicon(company.value.favicon)
}

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | ${siteName.value}` : siteName.value
  },
})
</script>

<template>
  <app-layout />
</template>
